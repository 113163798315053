/*
Theme Name: Drupal 7 Gulp Starter Theme
Theme URI: https://github.com/lasseyls/Drupal-7-Starter-Theme
Description: Drupal 7 Gulp Starter Theme for use as a starting template for building custom themes.
Author: Lasse Moos
Author URI: http://www.yourlocalstudio.dk
License: GNU General Public License v2.0
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Version: 1.0
*/

// Reset, Variables, Mixins, Etc
// DO NOT EDIT ORDER OF FILES
@import "partials/mixins";
@import "partials/variables";
@import "partials/normalize";

// Globals
@import "partials/global";
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,300,500);

// The grid
@import "partials/grid";

// Page and Site Styles
@import "partials/page";
@import "partials/posts";
@import "partials/blocks";
@import "partials/search";
@import "partials/forms";

// Helper Classes, Print, Etc
@import "partials/helpers";
@import "partials/print";
