/* FORMS */

form { }

.webform-client-form #webform-component-last-name span.field-prefix,.webform-client-form #webform-component-first-name span.field-prefix,.webform-client-form #webform-component-email span.field-prefix,.webform-client-form #webform-component-phone span.field-prefix {
  width: 46px
}

.webform-client-form #webform-component-last-name input,.webform-client-form #webform-component-first-name input,.webform-client-form #webform-component-email input,.webform-client-form #webform-component-phone input {
  padding-left: 56px
}

.webform-client-form #webform-component-last-name span.field-prefix:before,.webform-client-form #webform-component-first-name span.field-prefix:before {
  font-size: 14px;
  margin-top: -7px
}

.webform-client-form #webform-component-email span.field-prefix:before {
  font-size: 12px;
  margin-top: -6px
}

.webform-client-form #webform-component-phone span.field-prefix:before {
  font-size: 20px;
  margin-top: -10px
}

.webform-client-form {
  font-size: 16px;

  .form-item {
    margin-bottom: 10px;
    float: left;
    width: 47.5%;
    margin-left: 1.25%;
    margin-right: 1.25%;
    position: relative;

    select,
    input[type=text] {
      width: 100%;
    }

    input.webform-calendar {
      margin-top: 9px;
      height: 30px;
    }
  }

  .form-item-submitted-birthdate-day,
  .form-item-submitted-birthdate-month,
  .form-item-submitted-birthdate-year {
    select {
      width: 25%;
    }
  }

  .webform-component--cv {
    float: none;
  }

  .form-actions {
    margin: 0 10px;
  }

  span.field-prefix {
    @extend .ir;
    width: 46px;
    height: 46px;
    top: 1px;
    left: 1px;
    display: block;
    position: absolute;
    background-color: #0db9cf!important;
    border-radius: 4px 0 0 4px;

    &:after {
      content: "";
      font-family: 'Icons';
      position: absolute;
      top: 30%;
      left: 0;
      width: 100%;
      text-align: center;
      font-weight: 300;
      line-height: 1;
      color: #fff;
      text-indent: 0;
    }
  }
}



.webform-client-form .form-item:nth-child(2n+1) {
  clear: left
}

.webform-component--birthdate {
  width: 100% !important;
}

.webform-component--firstname{
  clear: none !important;
}
.webform-client-form .form-item .form-item {
  margin-bottom: 0;
  float: none;
  width: 100%;
  margin-left: 0;
  margin-right: 0
}

.webform-client-form .form-item .form-item:nth-child(2n+1) {
  clear: none
}

.webform-client-form .form-item label {
  margin-bottom: 5px
}

.webform-client-form .form-file {
  display: inline-block;
}

.webform-client-form .form-item input[type="password"],.webform-client-form .form-item input[type="email"],.webform-client-form .form-item input[type="text"] {
  border: 1px solid #cfcfcf;
  padding: 2% 2% 2% 56px;
  height: 48px;
  font-size: 16px
}

.webform-client-form .form-item textarea {
  border: 1px solid #cfcfcf;
  border-bottom: none
}

.webform-client-form .form-item.webform-component-textarea {
  width: 97.5%
}

.webform-client-form .form-item select {
  height: 48px;
  font-size: 16px;
  padding: 2%;
  background-color: #f0f0f0;
  border: 1px solid #f0f0f0;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  behavior: url(/sites/all/libraries/PIE.htc);
}

.webform-component--skill-area {
  float: none !important;
}

.webform-component--skill-area:before,
.webform-component--skill-area:after {
  content:"";
  display:table;
}

.webform-component--skill-area:after {
  clear:both;
}

.webform-client-form .form-item-submitted-birthdate-month select,
.form-item-submitted-birthdate-day select,
.form-item-submitted-birthdate-year select {
  max-width: 100px !important;
}

.webform-client-form .form-item .form-radios .form-item {
  margin-top: 5px
}

.webform-client-form .form-item .form-radios .form-item:first-child {
  margin-top: 0
}

.webform-client-form .form-actions {
  margin-top: 10px;
  margin-bottom: 10px;

  input {
    background: #dc1b74 url("../images/arrow-next-white.png") no-repeat 87% center;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1px;
    padding: 10px 50px 10px 15px;
    text-decoration: none;
    text-transform: uppercase;
    display: inline-block;
    border-radius: 4px;
    border: none;
  }
}

.webform-client-form div.error,.webform-client-form div.description {
  font-size: 16px;
  margin: 10px 0 10px
}

.webform-client-form textarea.error,.webform-client-form select.error {
  border: 1px solid #dc1b74
}

.webform-client-form #webform-component-last-name span.field-prefix,.webform-client-form #webform-component-first-name span.field-prefix,.webform-client-form #webform-component-email span.field-prefix,.webform-client-form #webform-component-phone span.field-prefix {
  width: 46px
}

.webform-client-form #webform-component-last-name input,.webform-client-form #webform-component-first-name input,.webform-client-form #webform-component-email input,.webform-client-form #webform-component-phone input {
  padding-left: 56px
}

.webform-client-form #webform-component-last-name span.field-prefix:before,.webform-client-form #webform-component-first-name span.field-prefix:before {
  font-size: 14px;
  margin-top: -7px
}

.webform-client-form #webform-component-email span.field-prefix:before {
  font-size: 12px;
  margin-top: -6px
}

.webform-client-form #webform-component-phone span.field-prefix:before {
  font-size: 20px;
  margin-top: -10px
}
