// IMAGE CIRCLE WITH BORDER
.image-circle {
  display: inline-block;
  border: 2px solid #0db9cf;
  border-bottom: 1px solid transparent;
  border-radius: 50%;
  transform: rotateZ(45deg);
  height: 100px;
  width: 100px;

  &.big {
    border: 3px solid #0db9cf;
    border-bottom: 3px solid transparent;
    height: 170px;
    width: 170px;

    img {
      border: 6px solid transparent;
    }
  }

  img {
    transform: rotateZ(-45deg);
    border: 5px solid transparent;
    border-radius: 50%;
    height: 100%;
    width: 100%;
  }
}

// IMAGE SQUARE WITH BORDER
.image-square {
  width: 155px;
  padding-top: 10px;
  padding-right: 13px;
  position: relative;

  &:before {
    background: #0db9cf;
    content: "";
    height: 2px;
    width: 55%;
    display: block;
    position: absolute;
    right: 0;
    top: 0;
  }

  &:after {
    background: #0db9cf;
    content: "";
    height: 36%;
    width: 2px;
    display: block;
    position: absolute;
    right: 0;
    top: 0;
  }

  img {
    width: 100%;
  }
}

// LINK PINK
.link-pink {
  background: #dc1b74;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
  padding: 7px 20px 7px 15px;
  text-decoration: none!important;
  text-transform: uppercase;
  display: inline-block;
  border-radius: 4px;

  &:hover {
    color: #fff;
    background-color: darken(#dc1b74, 10%);
  }

  &:before {
    background: url("../images/icon-pdf.png") no-repeat 0 0;
    content: '';
    width: 23px;
    height: 27px;
    display: inline-block;
    margin-right: 17px;
    vertical-align: middle;
  }

  &:after {
    background: url("../images/arrow-next-white.png") no-repeat 0 0;
    content: '';
    width: 7px;
    height: 11px;
    display: inline-block;
    margin-left: 62px;
    vertical-align: middle;
  }
}

html.fixed {
  @include bp(phone) {
    max-height: 100%;
    max-width: 100%;
    overflow: hidden;
  }
  body {
    @include bp(phone) {
      overflow-y: hidden;
    }
  }

  #container {
    @include bp(phone) {
      left: 280px;
      min-height: 100%;
      position: relative;
      top: 0;
      width: 100%;
    }
  }

  .l-page-hover {
    @include bp(phone) {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(37,48,78,0.76);
      overflow: hidden;
      z-index: 19;
    }
  }
}

/* BLOCKS */
.side-menu {
  @include bp(phablet) {
    display: none;
    -webkit-overflow-scrolling: touch;
  }
  @include bp(phone) {
    display: none;
    position: fixed;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100%;
    width: 280px;
    top: 0;
    left: 0;
    z-index: 20;
    background-color: #fff;
    -webkit-overflow-scrolling: touch;
  }
  .side-menu-content {
    @include bp(phone) {
      background-color: #fff;
      padding: 2em 1em;
    }
  }

  .block-menu {
    float: left;
    margin: 18px 0 0 34px;
    @include bp(phone) {
      @include clearfix;
      float: none;
      margin: 0;
    }
    @include bp(phablet) {
      margin-left: 0;
    }
    .menu {
      padding: 0;
      margin: 0;
      li {
        display: inline-block;
        vertical-align: middle;
        list-style-image: none;
        list-style-type: none;
        padding: 0 8px;
        margin: 0;
        border-right: 1px solid #25304f;
        line-height: 10px;

        a {
          font-size: 11px;
          font-weight: 500;
          color: #25304f;
          text-decoration: none;
          text-transform: uppercase;
          float: left;
        }
      }
      li.last {
        border: 0;
        a {
          text-indent: -99999px;
          background: url('../images/share.jpg') no-repeat 50% 50% transparent;
          width: 18px;
          height: 18px;
          overflow: hidden;
        }
      }
    }
  }

  .block-locale li a:hover,
  .block-locale li a:active,
  .block-locale li a:focus {
    background-color: #d4d4d4;
    color: #939498
  }

  .block-locale li.active .language-link {
    background-color: #25304e;
    color: #fff;
    cursor: default
  }

  .block-locale {
    float: right;
    margin: 15px 0 0 0;
    @include bp(phone) {
      @include clearfix;
      float: none;
    }

    .language-switcher-locale-url {
      float: left;
      padding: 0;
    }

    li  {
      list-style-type: none;
      float: left;
      margin: 0 0 0 5px;

      .language-link {
        display: inline-block;
        background-color: #ededed;
        font-weight: 500;
        line-height: normal;
        color: #adaeb1;
        padding: 8px 5px;
        font-size: 11px;
        text-transform: uppercase;

        &.locale-untranslated {
          opacity: 0.15;

          abbr {
            cursor: default;
          }
        }

        &.active {
          background-color: #25304e;
          color: #fff;
          cursor: default;
        }
      }

      abbr {
        cursor: pointer;
        border: 0;
      }
    }
  }

  .block-search {
    float: right;
    border: 1px solid #d8d8d8;
    border-radius: 5px 5px 5px 5px;
    -moz-border-radius: 5px 5px 5px 5px;
    -webkit-border-radius: 5px 5px 5px 5px;
    width: 100%;
    max-width: 275px;
    height: 40px;
    position: relative;
    @include bp(phone) {
      float: none;
    }

    @include bp(phablet) {
      margin-top: 9px;
    }

    .container-inline {
      position: relative;
      display: block;

      div {
        display: block;
        margin: 0;
      }
    }

    .form-type-textfield input {
      border: 0;
      height: 30px;
      width: 100%;
      padding: 0 10px 0 40px;
      margin: 5px 0;
      outline: none;
      background: none!important;
      border: none !important;
    }

    .form-actions {
      position: absolute;
      top: 0;

      &:after {
        position: absolute;
        left: 11px;
        content: '\e807';
        display: block;
        top: 5px;
        color: #D0D0D0;
        font-size: 21px;
        font-family: "Icons";
      }

      input {
        background: transparent;
        text-indent: -9999px;
        overflow: hidden;
        width: 30px;
        border: 0;
        height: 30px;
        position: absolute;
        left: 5px;
        top: 5px;
        z-index: 20;
      }
    }
  }

  .block-tb-megamenu {
    float: right;
    width: 100%;
    @include bp(phone) {
      @include clearfix;
      float: none;
    }
    .tb-megamenu-button{
      background: #25304f;
      display: none;
      border: 0;
      border-radius: 0;
      top: -50px;
      position: absolute;

      i {
        background: url('../images/burger-menu.png') no-repeat #25304f;
        height: 33px;
        width: 34px;
        border: 0;
        display: block;
      }
    }
    .tb-megamenu {
      background: transparent;
      .nav-collapse {
        float: right;
        @include bp(phone) {
          margin: 0;
          top: 15px;
          display: block;
        }
      }
    }
  }
}

.front {
  .region-middle-white-content {
    padding: 80px 0 63px;
    @include bp(phablet) {
        padding: 80px 5% 63px 5%;
    }
    @include bp(phone) {
        padding: 0;
    }
  }
}

#block-views-home-blocks-block-1--2,
#block-views-home-blocks-block-1 {
  .view-content {
    @extend .clearfix;
  }

  .content {
    position: relative;

    .views-row-1 {
      width: 730px;
      height: 493px;
      float: left;

      @media (max-width: 1110px){
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
      }

      @include bp(phone) {
        width: 100%;
        height: 170px;
      }
    }
    .views-row {
      position: relative;
      overflow: hidden;

      @media (max-width:1110px) {
        margin-bottom: 35px;
      }

      .views-field-field-mobile-image {
        display: none;
        @include bp(phone) {
          display: inline-block;
        }
      }
      .views-field-field-tags {
        position: absolute;
        bottom: 110px;
        left: 25px;
        right: 25px;
        z-index: 100;
        color: #c0c0c0;

        @include bp(phone) {
          bottom: 90px;
        }

        a {
          color: #c0c0c0;
          font-weight: 500;
          text-decoration: none;
          text-transform: uppercase;
          font-size: 19px;
          margin: 0;
          letter-spacing: 2px;

          @include bp(phone) {
            font-size: 12px;
            letter-spacing: normal;
          }

          &:before {
            content: '#';
          }
        }
      }

      .views-field-title {
        position: absolute;
        bottom: 25px;
        left: 25px;
        right: 25px;
        height: 80px;
        z-index: 100;
        @include bp(phone) {
          bottom: 0;
        }

        a {
          color: #ffffff;
          font-size: 26px;
          text-decoration: none;
          font-weight: bold;
          margin: 0;
          letter-spacing: 1px;
          @include bp(phone) {
            font-weight: 700;
            font-size: 19px;
            line-height: 17px;
          }
        }
      }
      .details {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 0 25px 25px 25px;
        background: url('../images/article-gradient.png') repeat-x 0 0 transparent;
        @include bp(phone) {
          /*background-size: contain;*/
        }
        .article-tags a {
          color: #c0c0c0;
          font-weight: 500;
          text-decoration: none;
          text-transform: uppercase;
          font-size: 19px;
          margin: 0;
        }
        .article-title a {
          float: left;
          color: #ffffff;
          font-size: 32px;
          text-decoration: none;
          font-weight: bold;
          margin: 0;
        }
      }
      img {
        width: 100%;
        height: auto;
      }
    }
    .views-row-2, .views-row-3 {
      width: 350px;
      height: 237px;
      float: right;

      @media (max-width: 1110px) {
        width: 50%;
        padding-left: 15px;
        padding-right: 15px;
      }
      @include bp(phone) {
        position: relative!important;
        width: 100%;
        height: auto;
      }

      .details-bg {
        @include bp(phone) {
          /*background-size: contain;*/
          min-height: 50%;
        }
      }
      .views-field-field-tags {
        position: absolute;
        bottom: 75px;
        left: 25px;
        right: 25px;
        z-index: 100;
        @include bp(phone) {
          bottom: 90px;
        }

        a {
          color: #c0c0c0;
          font-weight: 500;
          text-decoration: none;
          text-transform: uppercase;
          font-size: 9px;
          margin: 0;
        }
      }
      .views-field-title {
        position: absolute;
        bottom: 20px;
        left: 25px;
        right: 25px;
        height: 55px;
        z-index: 100;
        @include bp(phone) {
          bottom: 10px;
          height: auto;
        }

        a {
          color: #ffffff;
          font-size: 25px;
          text-decoration: none;
          font-weight: bold;
          margin: 0;
          @include bp(phone) {
            font-weight: 700;
            font-size: 19px;
            line-height: 17px;
          }
        }
      }
    }
    .views-row-3 {
      margin-top: 19px;

      @media (max-width: 1110px) {
        margin-top: 0;
      }
      @include bp(phone) {
        
      }
    }
  }
}

.region-middle-grey-content {
  background: #f8f8f8;
  padding: 7px 0;

  @include bp(phone) {
    background: transparent;
  }

  @include bp(phablet) {
    padding: 7px 5%;
  }

  .block-views {
    @extend .container;
    padding: 0;
  }

  .view-content {
    @extend .clearfix;
    margin: 0 -35px;

    @include bp(phone) {
      margin: 0;
    }
  }

  .views-row {
    float: left;
    width: 33%;
    padding: 0 35px;

    @include bp(phone) {
      float: none;
      padding: 35px;
      border-top: 1px solid #b0b0b0;
      width: 100%;
    }
  }

  .views-field-title {
    color: #25304f;
    font-weight: bold;
    font-size: 24px;
    text-transform: uppercase;

    @include bp(phablet) {
      font-size: 17px;
    }
  }

  .views-field-field-subtitle {
    color: #959595;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 3.6px;
    margin-top: 10px;

    @include bp(phablet) {
      font-size: 10px;
    }
  }

  .views-field-field-youtube {
    margin-top: 15px;
    img {
      width: 100%;
    }
    iframe {
      @include bp(phone) {
        width: 100%;
      }
    }
  }

  .views-field-field-link {
    margin-top: 10px;

    a {
      color: #0db9cf;
      text-transform: uppercase;
      font-size: 13px;
      letter-spacing: 1px;

      &:after {
        background: url("../images/arrow-next-blue.png") no-repeat 0 0 transparent;
        content: "";
        height: 11px;
        width: 20px;
        margin-left: 10px;
        display: inline-block;
      }
    }
  }
}

.region-bottom-grey-content {
  background: #f8f8f8;
  padding: 26px 0 50px;

  @include bp(phablet) {
    padding: 26px 5% 50px 5%;
  }
}

.front .region-bottom-grey-content {
    @include bp(phone) {
        display: none;
    }
    h2 {
        font-size: 32px;
        font-weight: 400;
        margin: 0;
    }
    .block-views{
        max-width: $pageWidth;
        margin: 0 auto;
        tbody {
            border: 0;
        }
        tr {
            background: transparent;
            border: 0;
        }
        ul {
            margin: 80px 0 0;
            li {
                list-style-type: none;
                width: 14%;
                margin: 0 13px;
                display: inline-block;
                vertical-align: top;
                .field-name-field-figure-number {
                    .field-item {
                        color: #0db9cf;
                        font-size: 70px;
                        line-height: 75px;
                        text-align: center;
                    }
                }
                .field-name-field-figure-label {
                    .field-item {
                        color: #4e4e4e;
                        text-align: center;
                        font-size: 21px;
                        line-height: 24px;
                        font-weight: 400;
                    }
                }
            }
            li.last {
                .field-name-field-figure-number {
                    .field-item {
                        font-size: 44px;
                        line-height: 62px;
                        margin: 10px 0 0;
                    }
                }
            }
        }


    }
}

.messages.error,
.messages.status {
    float: left;
}

.f-col {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  float: none;
  color: #797979;

  .field-image {
    img {
      width: 100%;
    }
  }

  .block-title {
    color: #242e51;
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 32px;
  }
}

.f-col-33 {
  width: 33%;
  padding: 0 1em;

  @include bp(phone) {
    width: 100%;
    padding: 1em;
  }
}

.f-col-50 {
  width: 49%;
  padding: 0 1em;
   @include bp(phone) {
    width: 100%;
   }
}

.f-col-67 {
  width: 66%;
  padding: 0 1em;

  @include bp(phone) {
    width: 100%;
    padding: 1em;
  }
}

.field-collection-view {
  padding: 0;
  border: 0;
  margin: 0;
}

.field-collection-container {
  border: 0;
  padding: 0;
  margin: 0;

  &.grey {
    background-color: #f8f8f8;
  }

  .container {
    @include bp(phone) {
        padding: 0 10px!important;
    }
  }

  .field-items .field-item {
    margin: 0;
  }

  .section-title {
    color: #242e51;
    margin-top: 0;
    font-size: 31px;
    font-weight: normal;
    margin-bottom: 20px;

    @include bp(phone) {
        font-size: 22px;
        margin: 15px 0;
        padding: 0 15px;
    }
  }

  .section-desc {
  }
}

.f-container {
  margin: 0 -1em;

  @include bp(phone) {
      margin: 0;
  }

  .f-col-blue {
    .block-title {
      color: #0db9cf;
      font-size: 70px;
      line-height: 75px;
      text-align: center;
    }

    .block-content {
      color: #4e4e4e;
      text-align: center;
      font-size: 21px;
      line-height: 24px;
      font-weight: 400;
    }
  }
}

.f-container-2 {
  .f-col {
    width: 49.5%;
    text-align: center;
    @include bp(phone) {
      width: 100%;
    }
  }
}

.f-container-3 {
  .f-col {
    width: 33%;
    text-align: center;
  }
}

.f-container-4 {
  .f-col {
    width: 24.5%;
    text-align: center;
  }
}

.f-container-5 {
  .f-col {
    width: 19.5%;
    text-align: center;
  }
}

.f-container-6 {
  .f-col {
    width: 16%;
    text-align: center;
  }
}

.f-container-no-margin {
  @include bp(phone) {
    margin: 0 1em;
  }
}

.accordion {
  padding: 5px;

  .accordion-title {
    font-size: 14px;
    cursor: pointer;
    color: #01b9d1;
    text-transform: uppercase;
    margin: 0;
    font-weight: bold;
    padding: 25px 20px;
    background-color: #f8f8f8;

    .section-title {
      font-size: 14px;
      color: #01b9d1;
      text-transform: uppercase;
      margin: 0;
    }

    .accordion-icon {
      background: url('../images/plus-icon.png') no-repeat center center;
      width: 17px;
      height: 17px;
      float: right;
    }

    &.active {
      color: #242e51;

      .accordion-icon {
        background: url('../images/minus-icon.png') no-repeat center center;
      }
    }
  }

  .accordion-content {
    display: none;
    padding: 5px 20px;
    background-color: #f8f8f8;
  }
}

.user-name {
  font-size: 26px;
  font-weight: 500;
  color: #25304f;

  div {
    display: inline;
  }
}

.user-expertise {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 2px;
  margin: 3px 0 15px 0;

  p {
    margin: 0 0 2px 0;
  }
}

.user-teaser {

  .user-img {
    display: inline-block;
    vertical-align: middle;
  }

  .user-content {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    width: 65%;
  }

  .user-link {
    a {
      background: transparent url("../images/arrow-next-blue.png") no-repeat scroll 100% 50%;
      color: #0db9cf;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 1px;
      padding: 0 15px 0 0;
      text-decoration: none;
      text-transform: uppercase;
    }
  }
}

#block-views-serialized-expertise-block,
#block-views-publication-block,
#block-views-expertise-block {
  @extend .container;
  text-align: center;
  margin-top: 50px!important;
  margin-bottom: 50px!important;

  h2 {
    font-size: 32px;
    color: #25304f;
    font-weight: normal;
  }

  .content {
    text-align: left;
  }
}

#block-views-serialized-expertise-block,
#block-views-expertise-block {

  .view-content {
    @extend .clearfix;
  }

  .accordion {
    width: 50%;
    float: left;

    @include bp(phone) {
      width: 100%;
      padding: 0;
      float: none;
    }
  }
}

#block-views-publication-block {

  .content {
    @include bp(phone) {
      padding: 0 1em;
    }
  }

  caption {
    text-transform: uppercase;
    color: #0db9cf;
    font-size: 23px;
    padding: 0;
    margin: 30px 0 10px;

    @include bp(phone) {
        font-size: 13px;
    }
  }

  table {
    width: 100%;
  }

  thead {
    th {
      border: 0;
      padding: 0 20px 20px 20px;
    }
  }

  tbody {
    background-color: #fff;
    border: 0;

    tr {
      border: 0;
      background: none;
    }

    td {
      padding: 15px 20px;

      &.views-field-field-long-title {
        width: 50%;
      }

      &.views-field-faculty-publication-authors {
        width: 20%;
      }

      &.views-field-field-support,
      &.views-field-field-date-from-json {
        width: 10%;
      }

      a {
        color: #000;
        text-decoration: none;

        &:hover {
          color: #0db9cf;
          text-decoration: underline;
        }
      }
    }
  }
}

.view-publications-by-department,
.view-ouvrages {

  > .view-content {
    @extend .container;
    @extend .clearfix;
    max-width: 1140px;

    .views-row {
      float: left;
      min-height: 350px;
      width: 50%;
      padding: 0 1em 2em 1em;

      @include bp(tablet) {
        min-height: 100px;
        width: 100%;
        float: none;
        margin-bottom: 2em;
      }
    }
  }
}

.view-publications-by-department,
.view-ouvrages {

  .display-content {

    @include bp(phone) {
      width: calc(100% - 155px);
    }
  }
}

.display {

  &-image,
  &-content {
    float: left;
  }

  &-image {
    width: 155px;

    img {
      width: 100%;
    }
  }

  &-content {
    width: 66%;
    padding: 0 0 0 21px;

    @include bp(phone) {
      width: calc(100% - 105px);
      @include clearfix;
    }

    h3 {
      margin-top: 9px;
      margin-bottom: 0;
      font-size: 26px;
      color: #25304f;
    }
  }

  &-author {
    margin-bottom: 10px;

    div,
    span {
      display: inline-block;
      color: #797979;
      text-transform: uppercase;
      font-size: 10px;
      letter-spacing: 2px;
      font-weight: bold;
    }
  }

  &-body {
    color: #7a7a7a;

    @include bp(phone) {
      float: left;
      width: 70%;
    }
  }

  &-link {
    @include bp(phone) {
      float: right;
      width: 30%;
      text-align: center;
    }
    a {
      background: transparent url("../images/arrow-next-blue.png") no-repeat scroll 100% center;
      color: #0db9cf;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 1px;
      padding: 0 15px 0 0;
      text-decoration: none;
      text-transform: uppercase;
      @include bp(phone) {
        background: transparent url("../images/icon-next-bg.png") no-repeat scroll 100% center;
        background-size: cover;
        font-size: 0;
        width: 55px;
        height: 55px;
        display: inline-block;
      }
    }
  }

  &-user {
    .display-image {
      width: 105px;
      text-align: center;
    }

    .display-first,
    .display-last {
      color: #25304f;
      font-size: 22px;
      line-height: 1;
      word-break: break-all;
    }

    .display-first {
      margin-top: 10px;
    }

    .display-last {
      text-transform: uppercase;
    }
  }
}

#views-exposed-form-publications-by-department-publications-by-type,
#views-exposed-form-publications-by-department-publications-by-chair,
#views-exposed-form-publications-by-department-publications-by-research,
#views-exposed-form-actualites-page,
#views-exposed-form-ouvrages-page,
#views-exposed-form-actualites-page-1 {
  .views-exposed-form {
    background-color: #f7f7f7;
    padding: 4em 0;
    margin-bottom: 45px;

    @include bp(phone) {
      padding: 1em 0;
    }

    label {
      font-weight: normal;
      font-size: 16px;
      color: #0db9cf;
    }
  }

  .views-exposed-widgets {
    @extend .container;
    margin-top: 0;
    margin-bottom: 0;
  }

  .views-exposed-widget {

    @include bp(phone) {
      float: none;
      padding: .5em 1em;
      width: 100%;
    }
  }

  .views-widget-filter-combine {
    height: 40px;
    padding: 0;
    margin: 30px 0 0 0;

    @include bp(phone) {
      margin: 0 0 40px 0;
    }

    .form-type-textfield {
      border: 1px solid #d8d8d8;
      border-radius: 5px;
      display: inline-block;
      position: relative;

      @include bp(phone) {
        width: 100%;
        background-color: #f7f7f7;
        border: 0;
        padding: 10px 0;
      }

      input {
        border: 0;
        height: 30px;
        padding: 0 10px;
        margin: 5px 0;
        outline: none;
        background: transparent;

        @include bp(phone) {
          width: 100%;
          background-color: #f7f7f7;
        }
      }
    }
  }

  .views-submit-button {
    margin-top: 34px;
    padding: 0;
    position: relative;

    &:after {
      position: absolute;
      right: 11px;
      content: '\e820';
      display: block;
      top: 11px;
      color: #fff;
      font-size: 17px;
      font-family: "Icons";
    }
  }

  .form-submit {
    @extend .link-pink;
    border: 0;
    text-transform: capitalize;
    height: 45px;
    padding-right: 40px;
    background: #dc1b74;
    margin: 0;


    @include bp(phone) {
      background: #dc1b74;
      width: 100%;
    }
  }

  .form-type-select {
    width: 240px;
    height: 45px;
    overflow: hidden;
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    color: #7a7a7a;
    position: relative;

    &:after {
      position: absolute;
      right: 11px;
      content: '\e820';
      display: block;
      top: 11px;
      color: #D0D0D0;
      font-size: 17px;
      font-family: "Icons";
    }

    @include bp(phone) {
      width: 100%;
    }

    select {
      background: transparent;
      width: 100%;
      padding: 5px;
      font-size: 16px;
      line-height: 1;
      border: 0;
      border-radius: 0;
      height: 45px;
      padding-left: 15px;
      outline: none;
      -moz-appearance: none;
      -webkit-appearance: none;

      &:-ms-expand {
        display: none;
      }

    }
  }
}

#views-exposed-form-publications-by-department-publications-by-type,
#views-exposed-form-publications-by-department-publications-by-chair,
#views-exposed-form-publications-by-department-publications-by-research,
#views-exposed-form-ouvrages-page {
  .views-submit-button {
    margin-top: 56px;
    margin-left: 15px;

    input {
      height: 42px;
    }
  }
}

#views-exposed-form-actualites-page,
#views-exposed-form-actualites-page-1 {
  .views-exposed-form {
    background-color: transparent;
    padding: 0;
  }
}

.view-expertises-page {
  margin-bottom: 50px;

  .accordion {
    padding: 10px 20px;
    background-color: #f8f8f8;
  }

  .accordion-title {
    padding: 15px 0;
    background: none;
  }

  .accordion-content {
    padding: 0;
    background: none;
  }

  .user-picture {
    height: 100%;
    width: 100%;
  }

  .container {
    margin-top: 0!important;
    margin-bottom: 0!important;
  }

  .views-row {
    float: left;
    width: 50%;
    padding: 1em;
    min-height: 240px;
    padding-bottom: 0;

    @include bp(phone) {
      float: none;
      width: 100%;
      min-height: 0;
      padding-bottom: 1em;
    }
  }

  .accordion:nth-child(even) {
    background-color: #fff;
  }
}

#block-views-home-blocks-block-2 {

  .youtube-wrapper {
    position: relative;

    .field-content {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      padding-top: 25px;
      height: 0;
      margin-top: 15px;
    }
  }

  .youtube-icon {
    background: url('../images/play-video.png') no-repeat center center;
    height: 100%;
    width: 100%;
    cursor: pointer;
  }

  .youtube-icon,
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .youtube-field-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .youtube-icon {
    z-index: 20;
  }

  .youtube-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
     -webkit-background-size: cover; /* pour anciens Chrome et Safari */
     z-index: 10;
  }
}

.view-contributors {
  @extend .container;

  .views-widget-filter-field_chaire_ref_tid {
    float: none;
    padding: 30px 0 20px;
    border-top: 1px solid #e2e2e2;
    border-bottom: 1px solid #e2e2e2;
    text-align: center;

    @include bp(phone) {
      border: 0;
    }

    .bef-checkboxes,
    .bef-toggle {
      display: inline-block;

      @include bp(phone) {
        display: block;
        padding: 0 15px;
      }
    }

    .bef-toggle {
      margin-right: 20px;
      text-transform: uppercase;
      font-size: 10px;
      font-weight: bold;
      letter-spacing: 2px;
      color: #7a7a7a;
      vertical-align: top;

      @include bp(phone) {
        text-transform: none;
        font-size: 18px;
        color: #5c5c5c;
        text-align: left;
        border-bottom: 1px solid #F7F7F7;
        font-weight: 500;
        margin: 0;
        padding-bottom: 10px;
      }
    }

    input {
      margin-top: 0;
      vertical-align: top;

      @include bp(phone) {
        float: right;
      }
    }

    label {
      text-transform: uppercase;
      font-size: 10px;
      font-weight: bold;
      letter-spacing: 2px;
      color: #7a7a7a;
      margin: 0 0 0 5px;
      display: inline-block;
      max-width: 175px;
      vertical-align: top;

      @include bp(phone) {
        text-transform: none;
        font-size: 18px;
        color: #5c5c5c;
        border-bottom: 1px solid #F7F7F7;
        font-weight: 500;
        padding: 10px 0;
        margin: 0;
        float: left;
      }
    }

    .form-item.highlight {

      label {
        color: #0db9cf;
      }
    }

    .form-type-bef-checkbox {
      width: 200px;
      text-align: left;

      input {
        @include bp(phone) {
          -webkit-appearance: none;
          width: 60px;
        	height: 31px;
        	background: #e8e8e8;
        	margin: 10px auto;
        	border-radius: 50px;
        	position: relative;
          outline: none;
        }

        &:after {
          @include bp(phone) {
            display: block;
          	width: 25px;
          	height: 25px;
            content: "";

          	border-radius: 50px;
          	transition: all .4s ease;
          	cursor: pointer;
          	position: absolute;
          	top: 3px;
          	left: 3px;
          	background: transparent;
            border: 2px solid #0db9cf;
          }
        }

        &:checked {
          background: #0db9cf;
        }

        &:checked:after {
        	left: 30px;
          border: 2px solid #fff;
        }
      }

      @include bp(phone) {
        @include clearfix;
        width: 100%;
      }
    }
  }

  .views-widget-filter-combine {
    width: 100%;
    height: 40px;
    text-align: center;
    padding: 0;
    margin: 30px 0 40px 0;

    @include bp(phone) {
      margin: 0 0 40px 0;
    }

    .form-type-textfield {
      border: 1px solid #d8d8d8;
      border-radius: 5px;
      width: 300px;
      display: inline-block;
      position: relative;

      @include bp(phone) {
        width: 100%;
        background-color: #f7f7f7;
        border: 0;
        padding: 10px 0;
      }

      &:before {
        background: url('../images/icon-search.png') no-repeat center center transparent;
        display: block;
        content: "";
        overflow: hidden;
        width: 30px;
        height: 30px;
        position: absolute;
        left: 5px;
        top: 5px;

        @include bp(phone) {
          top: 15px;
        }
      }

      input {
        border: 0;
        height: 30px;
        padding: 0 10px 0 40px;
        margin: 5px 0;
        outline: none;

        @include bp(phone) {
          width: 100%;
          background-color: #f7f7f7;
        }
      }
    }
  }

  .views-row {
    display: inline-block;
    vertical-align: top;
    width: 33%;
    margin-bottom: 3em;

    @include bp(phone) {
      width: 100%;
    }
  }
}

.view-professors-by-department {
  > .view-content {
    @extend .container;
    font-size: 0;

    .views-row {
      display: inline-block;
      vertical-align: top;
      width: 50%;
      margin-bottom: 3em;
      font-size: 14px;

      @include bp(phone) {
        width: 100%;
      }
    }
  }
}

.view-professors {
  .views-widget-filter-taxonomy_entity_index_tid_depth {
    float: none;
    padding: 30px 0 20px;
    border-top: 1px solid #e2e2e2;
    border-bottom: 1px solid #e2e2e2;
    text-align: center;

    @include bp(phone) {
      border: 0;
    }

    .bef-checkboxes,
    .bef-toggle {
      display: inline-block;

      @include bp(phone) {
        display: block;
        padding: 0 15px;
      }
    }

    .bef-toggle {
      margin-right: 20px;
      text-transform: uppercase;
      font-size: 10px;
      font-weight: bold;
      letter-spacing: 2px;
      color: #7a7a7a;
      vertical-align: top;

      @include bp(phone) {
        text-transform: none;
        font-size: 18px;
        color: #5c5c5c;
        text-align: left;
        border-bottom: 1px solid #F7F7F7;
        font-weight: 500;
        margin: 0;
        padding-bottom: 10px;
      }
    }

    input {
      margin-top: 0;
      vertical-align: top;

      @include bp(phone) {
        float: right;
      }
    }

    label {
      text-transform: uppercase;
      font-size: 10px;
      font-weight: bold;
      letter-spacing: 2px;
      color: #7a7a7a;
      margin: 0 0 0 5px;
      display: inline-block;
      max-width: 175px;
      vertical-align: top;

      @include bp(phone) {
        text-transform: none;
        font-size: 18px;
        color: #5c5c5c;
        border-bottom: 1px solid #F7F7F7;
        font-weight: 500;
        padding: 10px 0;
        margin: 0;
        float: left;
      }
    }

    .form-item.highlight {

      label {
        color: #0db9cf;
      }
    }

    .form-type-bef-checkbox {
      width: 200px;
      display: inline-block;
      vertical-align: top;
      text-align: left;

      input {
        @include bp(phone) {
          -webkit-appearance: none;
          width: 60px;
        	height: 31px;
        	background: #e8e8e8;
        	margin: 10px auto;
        	border-radius: 50px;
        	position: relative;
          outline: none;
        }

        &:after {
          @include bp(phone) {
            display: block;
          	width: 25px;
          	height: 25px;
            content: "";

          	border-radius: 50px;
          	transition: all .4s ease;
          	cursor: pointer;
          	position: absolute;
          	top: 3px;
          	left: 3px;
          	background: transparent;
            border: 2px solid #0db9cf;
          }
        }

        &:checked {
          background: #0db9cf;
        }

        &:checked:after {
        	left: 30px;
          border: 2px solid #fff;
        }
      }

      @include bp(phone) {
        @include clearfix;
        width: 100%;
      }
    }
  }

  .views-widget-filter-combine {
    width: 100%;
    height: 40px;
    text-align: center;
    padding: 0;
    margin: 30px 0 40px 0;

    @include bp(phone) {
      margin: 0 0 40px 0;
    }

    .form-type-textfield {
      border: 1px solid #d8d8d8;
      border-radius: 5px;
      width: 300px;
      display: inline-block;
      position: relative;

      @include bp(phone) {
        width: 100%;
        background-color: #f7f7f7;
        border: 0;
        padding: 10px 0;
      }

      &:before {
        background: url('../images/icon-search.png') no-repeat center center transparent;
        display: block;
        content: "";
        overflow: hidden;
        width: 30px;
        height: 30px;
        position: absolute;
        left: 5px;
        top: 5px;

        @include bp(phone) {
          top: 15px;
        }
      }

      input {
        border: 0;
        height: 30px;
        padding: 0 0 0 40px;
        margin: 5px 0;
        outline: none;

        @include bp(phone) {
          width: 100%;
          background-color: #f7f7f7;
        }
      }
    }
  }
  > .view-filters {
    @extend .container;
  }

  > .view-content {
    @extend .container;

    .views-row {
      display: inline-block;
      vertical-align: top;
      width: 33%;
      margin-bottom: 3em;

      @include bp(phone) {
        width: 100%;
      }
    }
  }
}

.view-actualites.view-display-id-page,
.view-actualites.view-display-id-page_1 {
  .view-container-grey {
    .views-row {
      display: inline-block;
      vertical-align: top;
      width: 24.5%;
      margin-bottom: 2em;
      padding: 0 1em;
      height: 175px;
      position: relative;

      @include bp(phone) {
        width: 100%;
      }

      img {
        width: 100%;
        height: 100%;
      }

      .views-field-title {
        position: absolute;
        bottom: 20px;
        left: 25px;
        right: 25px;
        height: 55px;
        z-index: 100;

        @include bp(phone) {
          bottom: 0;
        }

        a {
          color: #ffffff;
          font-size: 16px;
          text-decoration: none;
          font-weight: bold;
          margin: 0;
        }
      }
    }
  }
}


#block-views-multiple-related-content-block {
  @include bp(phone) {
    margin: 0!important;
  }

  h2 {
    text-align: left;
    color: #0db9cf;
    font-size: 18px;

    @include bp(phone) {
      display: none;
    }
  }

  .views-row {
    width: 270px;
    height: 170px;
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;

    @include bp(phone) {
      width: 100%;
      height: 160px;
      margin: 0;
      overflow: hidden;
    }

    img {
      @include bp(phone) {
        width: 100%;
        height: 100%;
      }
    }

    .views-field-title {
      position: absolute;
      left: 25px;
      bottom: 0;
      right: 25px;
      height: 55px;
      z-index: 100;

      a {
        color: #ffffff;
        text-decoration: none;
        margin: 0;
        font-weight: 700;
        font-size: 17px;
        line-height: 17px;
        @include bp(phone) {
          font-weight: 700;
          font-size: 19px;
          line-height: 17px;
        }
      }
    }
  }
}

.block-addtoany {
  h2 {
    text-align: left;
    color: #0db9cf;
    font-size: 18px;

    @include bp(phone) {
			text-align: center;
      margin-bottom: 20px;
		}
  }

  .addtoany_list {
    line-height: normal !important;
  }

  .addtoany_list:before,.addtoany_list:after {
    content: "";
    display: table;
    line-height: 0;
  }

  .addtoany_list:after {
    clear: both;
  }

  .addtoany_list a.a2a_counter {
    line-height: 0;
    float: left;
    display: block;
    text-align: center;
  }

  .addtoany_list a.a2a_counter>span {
    height: auto;
    line-height: auto;
    width: auto;
    display: block;
  }

  .addtoany_list a:hover,.addtoany_list a:active,.addtoany_list a:focus {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
    opacity: 0.7;
  }

  .addtoany_list a:hover span,.addtoany_list a:active span,.addtoany_list a:focus span {
    filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
  }

  .addtoany_list span.a2a_count {
    line-height: normal !important;
    height: auto !important;
    width: auto !important;
    color: #fff;
    background-color: #25304e;
    margin: 0;
    border: none;
    display: inline-block;
  }

  .addtoany_list span.a2a_count:before,.addtoany_list span.a2a_count:after {
    display: none;
  }

  .addtoany_list span.a2a_count span {
    line-height: auto;
  }

  span.a2a_s__default {
    background-image: none !important;
    position: relative;
  }

  span.a2a_s__default:before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    line-height: 1;
    text-align: center;
    color: #fff;
  }

  span.a2a_s_facebook {
    background-color: #445d95;
  }

  span.a2a_s_facebook:before {
    content: '\e802';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-family: 'Icons';
  }

  span.a2a_s_twitter {
    background-color: #3c9cc4;
  }

  span.a2a_s_twitter:before {
    content: '\e806';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-family: 'Icons';
  }

  span.a2a_s_pinterest {
    background-color: #c9221f;
  }

  span.a2a_s_pinterest:before {
    content: '\e82d';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-family: 'Icons';
  }

  span.a2a_s_linkedin {
    background-color: #9a1a18;
  }

  span.a2a_s_linkedin:before {
    content: '\e804';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-family: 'Icons';
  }

  span.a2a_s_google_plus {
    background-color: #0077b5;
  }

  span.a2a_s_google_plus:before {
    content: '\e800';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-family: 'Icons';
  }

  span.a2a_s_a2a {
    background-color: #dc1b74;
  }

  span.a2a_s_a2a:before {
    content: '\e816';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-family: 'Icons';
  }

  .addtoany_list a.a2a_counter {
    padding: 0 4px 8px
  }

  .addtoany_list a>span.a2a_count {
    font-size: 10px !important;
    margin-top: -7px;
    padding: 5px 12px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
  }

  .addtoany_list a>span.a2a_s__default {
    width: 60px;
    height: 60px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
  }

  .addtoany_list a>span.a2a_s__default:before {
    font-size: 32px;
    margin-top: -16px
  }

}

.hide-upload-button {
  .form-submit {
    display: none;
  }
}


.facebook:before {
  content: "";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-family: 'Icons'
}

.facebook:hover,.facebook:active,.facebook:focus {
  background-color: #445d95;
  border-color: #445d95
}

.twitter:before {
  content: "";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-family: 'Icons'
}

.twitter:hover,.twitter:active,.twitter:focus {
  background-color: #3c9cc4;
  border-color: #3c9cc4
}

.youtube:before {
  content: "";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-family: 'Icons'
}

.youtube:hover,.youtube:active,.youtube:focus {
  background-color: #e12b28;
  border-color: #e12b28
}

.linkedin:before {
  content: "";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-family: 'Icons'
}

.linkedin:hover,.linkedin:active,.linkedin:focus {
  background-color: #9a1a18;
  border-color: #9a1a18
}

.gplus:before {
  content: "";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-family: 'Icons'
}

.gplus:hover,.gplus:active,.gplus:focus {
  background-color: #0077b5;
  border-color: #0077b5
}

.instagram:before {
  content: "";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-family: 'Icons'
}

.instagram:hover,.instagram:active,.instagram:focus {
  background-color: #da5434;
  border-color: #da5434
}

a.menu_icon,
a.soc {
  position: relative;
  display: inline-block;
  font-size: 0;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

a.menu_icon:before,
a.soc:before {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  line-height: 1
}

a.menu_icon {
  height: 58px;
  width: 58px;
  border: 3px solid #fff;
}

a.menu_icon:before {
  margin-top: -16px;
  height: 32px;
  font-size: 32px;
}
