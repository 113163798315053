/* GENERAL */
body {
  font-family: 'Roboto', sans-serif!important;

  &.side-menu-deployed {
    overflow-y: hidden;
  }
  @include bp(phablet) {
    margin-top: 100px !important;
  }
  @include bp(phone) {
    margin-top: 50px !important;
  }
}

html {
  overflow-x: hidden;
}

html body.admin-menu {
  @include bp(phablet) {
    margin-top: 100px !important;
  }
  @include bp(phone) {
    margin-top: 50px !important;
  }
}

a,
a:visited {
    color: $linkColor;
}
a:hover {
    color: $linkHoverColor;
}

::-moz-selection {
    background: $selectionColor;
    text-shadow: none;
}

::selection {
    background: $selectionColor;
    text-shadow: none;
}

/* HEADINGS / TYPOGRAPHY */
h1,
h2,
h3,
h4 {
    font-weight: normal;
    margin: 0 0 10px 0;
}
h1 { }
h2 { }
h3 { }
h4 { }

/* STRUCTURE */
.container {
    max-width: $pageWidth;
    margin: 20px auto;
    padding: 0!important;
}

.only-mobile {
    display: none;
    @include bp(phone) {
        display: block;
    }
}



/* HEADER */
header {
  //height: 170px;
  background: #FFFFFF;
  font-family: 'Roboto', sans-serif;
  .container {
    margin: 0 auto;
    padding: 3px 0 0 0;
    @include bp(phablet) { width: auto; }
  }
  .site-slogan {
    font-size: 30px;
    color: #7b7c7e;
    line-height: 20px;
    font-weight: 300;
    margin: 54px 0 0 0;
    padding: 0 0 0 70px;
    @include bp(phablet) { display: none; }
  }
}

#logo {
  height: 109px;
  max-width: 100%;
  display: block;
  margin-top: 20px;
  margin-bottom: 5px;
  @include bp(phablet) { display: none; }
  img {
    height: 100%;
    margin: 0;
    width: auto;
    max-width: 100%;
  }
}

.mobile-menu {
  display: none;
  width: 100%;
  height: 100px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  background-color: #fff;

  @include bp(phablet) {
    display: block;
    width: 100%;
    height: 100px;
  }
  @include bp(phone) {
    height: 50px;
  }


  * {
    font-size: 14px;
  }

  .nav-trigger {
    @include bp(phablet) {
      background: transparent;
      display: inline-block;
      padding: 35px 25px;
      width: 100px;
      height: 100px;
      background-color: #25304f;
      position: relative;
      z-index: 100;
    }

    @include bp(phone) {
      padding: 10px 13px;
      height: 50px;
      width: 50px;
    }

    &.active {
      @include bp(phablet) {
        background-color: #fff;
      }

      .icon-bar {
        @include bp(phablet) {
          background-color: #25304f;
        }
      }
    }
  }

  .icon-bar {
    display: block;
    height: 2px;
    margin: 6px 0;
    background-color: #fff;
  }

  #logo-mobile {
    display: inline-block;
    width: 70%;
    text-align: center;
    vertical-align: top;

    @include bp(phablet) {
      line-height: 100px;
    }

    @include bp(phone) {
      line-height: 50px;
    }

    img {
      @include bp(phablet) {
        width: auto;
      }
      @include bp(phone) {
        height: 35px;
      }
    }
  }

  .extra-trigger {
    @include bp(phablet) {
      width: 100px;
      height: 100px;
      background: url('../images/icon-school.png') no-repeat center center #dc1b74;
      display: inline-block;
      background-size: 2em;
      position: absolute;
      right: 0;
    }

    @include bp(phone) {
      height: 50px;
      width: 50px;
    }
  }
}

/* NAV */
.tb-megamenu {
  .nav-collapse {
    float: right;
    text-align: right;
    background: transparent;

    @include bp(phablet) {
      float: none!important;
      height: auto!important;
      position: static;
    }

    .level-0 {
      > li {
        background: #25304f;

        @include bp(phablet) {
          background-color: #fff;
        }

        &:hover {
          .dropdown-toggle,
          > a {
            color: #ccc!important;

            @include bp(phablet) {
              color: #25304f!important;
            }

            .caret {
              border-top-color: #ccc!important;
              border-bottom-color: #ccc!important;
            }
          }
        }

        &:last-child {
          > a {
            border-right: 0!important;
          }
        }

        &.open {
          .caret-mobile:after {
            @include bp(phablet) {
              background-image: url('../images/minus-icon.png')!important;
            }
          }
        }

        > .megamenu-toggle-carret {
          text-align: left;

          @include bp(phablet) {
            padding: 8px 15px;
          }

          .caret {
            border-top-color: #FFF!important;
            border-bottom-color: #FFF!important;
            margin-top: 5px!important;
          }

          a {
            background: #25304f!important;
            font-size: 10px;
            text-decoration: none;
            text-transform: uppercase;
            text-align: left;
            padding: 8px 15px;
            margin: 15px 0;
            display: block;
            color: #fff!important;
            border-right: 1px solid #FFF!important;

            &.active {
              background-color: #f3f3f3;
            }

            @include bp(phablet) {
              background: #fff!important;
              border: 0!important;
              color: #25304f!important;
              padding: 0!important;
              font-size: 24px;
              font-weight: 500;
              margin: 0!important;
            }
          }
        }

        &.level-1 {
          border-right: 0;

          &:last-child {
            a {
              border: 0!important;
            }
          }

          > .megamenu-toggle-carret {
            @include bp(phablet) {
              position: relative;
            }

            & > .caret-mobile {
              @include bp(phablet) {
                z-index: 30;
                display: block!important;
                position: absolute;
                right: 0;
                margin: 0;
                margin-top: -20px!important;
                border: 0;
                top: 50%;
                width: 40px;
                height: 40px;
                background-color: #ebebeb;
              }

              &:after {
                @include bp(phablet) {
                  background: url('../images/plus-icon.png') no-repeat center center transparent;
                  content: "";
                  height: 20px;
                  width: 40px;
                  display: block;
                  position: absolute;
                  margin-top: -10px;
                  right: 0;
                  top: 50%;
                }
              }
            }
          }

          .caret {
            display: inline-block!important;
            @include bp(phablet) {
              display: none!important;
            }
          }

          > .mega-dropdown-menu {
            padding: 15px;
            box-shadow: 0 1px 10px #777;

            @include bp(phablet) {
              padding: 0;
              box-shadow: 0 0 0;
              padding-left: 20px;
            }
          }
        }

        .tb-megamenu-submenu {
          border: 0;
          box-shadow: none;
          background-color: #f3f3f3;

          @include bp(phablet) {
            background-color: transparent;
          }

          .row-fluid {
            .span6 {
              width: 50%;
              padding: 0;
              margin: 0;
            }
            .mega-inner {
              padding: 0;
              .tb-megamenu-column {
                padding-right: 0;
              }
              a {
                background: transparent!important;
                color: #9c9c9c!important;
                text-align: left;
                padding: 0!important;
                font-weight: normal;
                text-transform: none;
                font-size: 12px;
                border: 0;

                &:hover {
                  border: 0;
                }

                @include bp(phablet) {
                  background: transparent!important;
                  border: 0!important;
                  color: #25304f!important;
                  font-weight: 400;
                  font-size: 20px;
                  line-height: normal;
                  color: #25304e;
                  padding: 14px 0!important;
                  margin: 0;
                }
              }

              .mega-group-title {
                color: #25304f!important;
                font-size: 16px;
                font-weight: bold;
                margin-top: 5px;
                margin-bottom: 15px;
                border: 0;

                &:hover {
                  border: 0;
                }

                @include bp(phablet) {
                  margin: 0;
                  font-weight: normal;
                  font-weight: 500;
                  text-transform: uppercase;
                  border-bottom: 1px solid #25304e!important;
                  padding-bottom: 10px;
                  font-size: 20px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.nav-container {
  width: 100%;
  float: left;
  text-align: right;
  height: 58px;
  @include bp(phablet) {
    position: absolute;
    width: 80%;
    left: -100%;
  }

  nav {
    #main-menu {
      margin: 0;
      float: right;
      background: #25304f;
      padding: 0 15px;
      @include bp(phablet) {
        width: 100%;
      }
      li {
        float: left;
        height: 58px;
        line-height: 58px;
        padding: 0;
        @include bp(phablet) {
          width: 100%;
        }
        a {
          color: #ffffff;
          font-size: 11px;
          text-decoration: none;
          text-transform: uppercase;
          border-right: 1px solid #676f84;
          display: inline-block;
          height: 20px;
          line-height: 20px;
          padding: 0 5px;
          @include bp(phablet) {
            width: 100%;
            text-align: center;
          }
        }
      }
      li.last{
        a {
          border: 0;
        }
      }
    }
  }
}


/* MAIN */


.top-image-content {
  background-color: #ccc;
  background-repeat: no-repeat;
  background-size: cover;
  height: 380px;
  width: 100%;
  position: relative;
  @include bp(phone) {
      height: 200px!important;
      margin-bottom: 50px;
  }

  &.small-size {
    height: 245px;

    .top-image-content-inside {
      bottom: 95px;
    }
  }

  &-inside {
    position: absolute;
    width: 1110px;
    left: 50%;
    margin: 0 0 0 -555px;
    bottom: 40px;

    @include bp(tablet) {
      width: 100%;
      margin: 0;
      left: 5%;
    }

    @include bp(phone) {
      background: #ffffff;
      left: 25px;
      right: 25px;
      bottom: 0!important;
      text-align: center;
      padding: 0.5em;
      width: auto;
      margin: 0;

      .breadcrumb {
        display: none;
      }
    }
  }

  .block-menu-block {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    line-height: 0;

    @include bp(phone) {
      position: static;
      padding-top: 210px;
      padding-left: 25px;
    }

    h2 {
      display: none;
    }

    .menu-block-wrapper {
      display: inline-block;

      @include bp(phone) {
        display: block;
      }
    }

    ul.menu {
      margin: 0;
      padding: 0;
      font-size: 0;
      overflow: hidden;

      @include bp(phone) {
      }

      li {
        margin: 0;
        padding: 0;
        display: inline-block;
        vertical-align: top;

        @include bp(phone) {
        }

        &:hover,
        &.active {
          a {
            background-color: #0db9cf;
            color: #fff;
          }
        }

        &:last-child {
          a {
            border-right: 0;
          }
        }

        a {
          border-right: 1px solid #cacaca;
          background-color: #f5f5f5;
          color: #797979;
          padding: 30px 40px;
          text-decoration: none;
          text-transform: uppercase;
          font-size: 14px;
          font-weight: 700;
          white-space: nowrap;
          overflow: hidden;
          display: inline-block;
          line-height: 1;
          text-overflow: ellipsis;
          @include bp(tablet) {
            padding: 15px 20px;
          }

          @include bp(phone) {
            padding: 10px 15px;
            max-width: 120px;
            font-size: 11px;
            font-weight: 400;
          }
        }
      }
    }
  }

  .breadcrumb {
    background: transparent;
    margin: 0;
    padding: 0;
    line-height: 1.2;

    a {
      color: #fff;
      text-shadow: 1px 1px 0 #000;
      font-size: 16px;

      &:after {
        content: '>';
        display: inline-block;
        margin: 0 5px;
      }

      &:last-child:after {
        display: none;
      }
    }
  }


  h1,
  .hero-title {
      color: #ffffff;
      font-size: 56px;
      font-weight: 300;
      text-shadow: 1px 1px 0 #000;
      margin: 0;
      @include bp(phone) {
          color: #25304f;
          font-size: 2.2em;
          text-shadow: none;
      }
  }
}

.view-header-top-image {
  .node-page {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.node-page {
  > .container {
    padding: 0;
  }

  .field-type-text {
    margin: 38px 0px 15px;
    color: #263054;
    font-size: 32px;
    @include bp(phone) {
      font-size: 22px;
      margin: 15px 0;
      padding: 0 25px;
    }
  }

  .field-name-body {
    line-height: 23px;
    color: #797979;
    @include bp(phone) {
        padding: 0 25px;
    }

    h2 {
      margin-top: 0;
      margin-bottom: 20px;
    }
  }

  .field-type-link-field {
    margin-top: 10px;
    @include bp(phone) {
      padding: 0 25px;
    }

    a {
      color: #0db9cf;
      font-size: 12px;
      font-weight: 400;
      padding: 0 15px 0 0;
      background: url('../images/arrow-next-blue.png') no-repeat 100% 50% transparent;
      text-transform: uppercase;
      text-decoration: none;
      letter-spacing: 1px;
    }
  }
  .links.inline {
    @include bp(phone) {
        padding: 0;
    }
  }
}
#block-views-home-blocks-block-1 {
  max-width: $pageWidth;
  margin: 20px auto;
  padding: 0;
  @include bp(phone) {
    margin-bottom: 0;
  }
}

/* SIDEBAR */
aside { }

/* FOOTER */
footer {
  #block-menu-menu-social-menu {
    background: url('../images/footer-lines.png') no-repeat 50% 53% #343434;
    height: 120px;
    @include bp(phone) {
      background: #343434;
    }
    .menu {
      text-align: center;
      margin: 0;
      padding: 35px 0;
      li {
        list-style-image: none;
        list-style-type: none;
        display: inline-block;
        a {
          overflow: hidden;
          text-indent: -99999
        }
      }
    }
  }
    .custom-region-map {
        background: url('../images/google-map.jpg') no-repeat 0 0 #2b2b2b;
        height: 234px;
        @include bp(phone) {
            background: #2b2b2b;
        }
        .region-footer {
            @extend .clearfix;
            max-width: $pageWidth;
            margin: 0 auto;
            padding: 36px 0 0;
            @include bp(phone) {
                display: none;
            }
            > .block {
                width: 25%;
                float: left;
            }

            .block-faculty-general {
              .content a {
                color: #ffffff;
              }
              .content a.link-button {
                border: 1px solid #ffffff;
                color: #ffffff;
                font-size: 12px;
                text-decoration: none;
                text-transform: uppercase;
                border-radius: 5px 5px 5px 5px;
                -moz-border-radius: 5px 5px 5px 5px;
                -webkit-border-radius: 5px 5px 5px 5px;
                padding: 8px 38px 8px 16px;
                display: inline-block;
                letter-spacing: 1.1px;
                position: relative;

                &:after {
                  position: absolute;
                  right: 11px;
                  content: '\e81f';
                  display: block;
                  top: 5px;
                  color: #D0D0D0;
                  font-size: 17px;
                  font-family: "Icons";
                }
              }
              .content a.link-button:hover {
                background: #ffffff;
                color: #2c2c2c;
                &:after {
                  color: #2D2D2D;
                }
              }
              h3 {
                font-size: 12px;
                color: #ffffff;
                font-weight: 500;
                text-transform: uppercase;
                margin: 0 0 18px 0;
              }
              p {
                font-size: 12px;
                color: #ffffff;
                font-weight: 400;
                line-height: 18px;
              }
            }

            #block-faculty-general-faculty-general-footer-virtual {
                width: 26%;
                img {
                  display: block;
                  width: 212px;
                  margin: 0 0 12px;
                }
            }

            #block-faculty-general-faculty-general-footer-address {
                width: 25.4%;
            }

            #block-faculty-general-faculty-general-footer-contact {
                width: 26%;
                p {
                    color: #b9b9b9;
                    line-height: 18px;
                }
            }
            #block-menu-menu-partner-menu {
              width: 825px;
              margin: 0 auto;
              text-align: center;
            }
            #block-menu-menu-quick-menu {
                width: 22%;
                h2 {
                    font-size: 12px;
                    color: #ffffff;
                    font-weight: 500;
                    text-transform: uppercase;
                    margin: 0 0 18px 0;
                }
                ul {
                  margin: 0;
                  padding: 0;
                }
                li{
                    list-style-type: none;
                    list-style-image: none;
                    padding: 0;
                    margin: 0;
                    a {
                        font-size: 12px;
                        color: #b9b9b9;
                        font-weight: 400;
                        line-height: 18px;
                        text-decoration: none;

                    }
                }

            }
        }
        .custom-mobile-actions {
            display: none;
            @include bp(phone) {
                display: inline-block;
                padding: 0;
                margin: 40px 0 0;
                width: 100%;
                li {
                    width: 33%;
                    float: left;
                    text-align: center;
                    padding: 0 3%;
                    a, p{
                        color: #ffffff;
                        font-size: 1em;
                    }
                }
            }
        }
    }
    .region-bottom-footer {
        background: #f7f7f7;
        text-align: center;
        padding: 60px 0 45px;
        @include bp(phone) {
            display: none;
        }
        .menu {
            display: inline-block;
            text-align: center;
            max-width: $pageWidth;
            padding: 0;
            margin: 0;
            li {
                display: inline-block;
                margin:0 10px;
                padding: 0;
                a {
                    font-size: 14px;
                    color: #adadad;
                    font-weight: 400;
                    text-decoration: none;
                    text-transform: uppercase;
                }
                a:hover {
                    color: #2b2b2b;
                }
            }
        }
    }
    .region-mention {
        text-align: center;
        padding: 20px 0;
        @include bp(phone) {
            display: none;
        }
        > div {
            display: inline-block;
            @include bp(phone) {
                float: right;
                width: 51%;
                text-align: right;
            }
        }
        .content {
            width: auto;
            display: inline;
            color: #a3a3a3;
            font-size: 12px;
            font-weight: 400;
            @include bp(phone) {
                font-size: 1em;
            }
            ul {
                padding: 0;
                margin: 0;
                @include bp(phone) {
                    display: none;
                }
                li {
                    list-style-image: none;
                    list-style-type: none;
                    a {
                        color: #a3a3a3;
                        font-size: 12px;
                        font-weight: 400;
                        text-decoration: none;
                        @include bp(phone) {
                            font-size: 18px;
                        }
                    }
                }
            }

        }
    }
}

/****************************************
PAGE TEMPLATES
*****************************************/

/* Home Page */
.home { }
.page-frontpage {
    .view-frontpage {
        .field-name-body {
            .field-item p {
                font-size: 16px;
                line-height: 24px;
                font-weight: 300;
            }
        }
    }
}

/* Professor Page */
.page-professors {

  .container {
    margin-top: 0;
    margin-bottom: 0;
  }

  .user-detail {
    text-align: center;

    .user-img {
      margin-top: -56px;
    }

    .user-parcours {
      margin: 30px 0 0 0;
      display: block;
      font-size: 22px;
      font-weight: 400;
      color: #0db9cf;
    }

    .user-resume {
      font-size: 16px;
    }
  }

  .user-cv {
    background: #F8F8F8;
    padding: 65px 46px;
    color: #797979;
    position: relative;

    &:after {
      position: absolute;
      content: '';
      display: block;
      left: 0;
      width: calc((100vw - 1050px) / 2);
      height: 100%;
      margin-left: 100%;
      background-color: #F8F8F8;
      top: 0;

      @include bp(phone) {
        display: none;
      }

      @include bp(tablet) {
        display: none;
      }
    }

    h3 {
      font-size: 14px;
    }

    ul {
      list-style: none;
      padding: 0 0 0 10px;
    }

    li {
      &:before {
          content: "•";
          margin-right: 10px;
          color: #0db9cf;
      }
    }
  }

  .user-cv-title {
    color: #0db9cf;
    margin-bottom: 23px;

    h2 {
      margin: 0;
      font-weight: normal;

      &:before {
        background: url("../images/icon-profile.png") no-repeat 0 0 transparent;
        content: "";
        height: 53px;
        width: 53px;
        margin-right: 10px;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  .user-qualification {
    color: #797979;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 2px;
    margin: 10px 0;
    font-size: 12px;
  }
}

.user-display {

  .f-col-50 {
    @include bp(phone) {
      width: 100%;
      padding: 1em 0;
    }
  }

  .user-img {
    display: inline-block;
  }
}

#sidebar-first {
  .block {
    margin-bottom: 40px;
  }
}

.professor-email {
  margin-top: 10px;
}
