// Font Face
// @include fontface('FONT_NAME', 'assets/fonts/', 'FONT_FAMILY');

// Structure
$pageWidth: 1110px;

// Text Selection
$selectionColor: orange;

// Links
$linkColor: #0db9cf;
$linkHoverColor: #0db9cf;

// Other
