/* POSTS */

.article-container {
  @extend .container;
  @extend .clearfix;
  position: relative;
  margin-top: 0;
  margin-bottom: 0;


  > div {
    width: 785px;
    padding-right: 2em;

    @include bp(phone) {
      width: 100%;
      padding: 0;
    }
  }

  .field-name-body {
    @include bp(phone) {
      margin-top: 15px;
    }
  }

  aside {
    width: 325px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    background: #f7f7f7;
    padding: 2em 0 2em 50px;

    @include bp(phone) {
      width: 100%;
      padding: 10px 0 0 0;
      position: static;
    }

    &:after {
      position: absolute;
      content: '';
      display: block;
      left: 0;
      width: calc((100vw - 1110px) / 2);
      height: 100%;
      margin-left: 100%;
      background-color: #F8F8F8;
      top: 0;

      @include bp(phone) {
        display: none;
      }

      @include bp(tablet) {
        display: none;
      }
    }
  }
}

.node-publication,
.node-article {
  padding: 2em 0;

  @include bp(phone) {
    padding: 0 2em;
  }

  h2 {
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0.5em;
    color: #25304f;
    font-size: 30px;

    @include bp(phone) {
      font-size: 20px;
      line-height: 1.3;
    }
  }

  h3 {
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0.5em;
    color: #0db9cf;
    margin-top: 1.5em;

    @include bp(phone) {
      font-size: 20px;
    }
  }

  .content {
    font-size: 16px;
    line-height: 1.5;
    color: #7a7a7a;
  }
}

article {
  clear: both;
  margin: 0 0 35px 0;
}

blockquote {
  background: url(assets/images/icons/blockquote.png) no-repeat 0 12px;
  padding: 10px 20px 10px 50px;
  font-style: italic;
}
blockquote p { }

.post { }

.alignleft {
  float: left;
  width: auto;
  margin: 5px 15px 15px 0;
}
.alignright {
  float: right;
  width: auto;
  margin: 5px 0 15px 15px;
}
.aligncenter {
  text-align: center;
  margin-bottom: 5px;
}
img.aligncenter {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

pre { }
code, tt { }

.meta { }
.postmetadata { }

/* SHARING */

.sharing h4 {
  margin-bottom: 0;
  padding-bottom: 0;
}
.sharing ul {
  list-style: none;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 5px;
  padding-bottom: 0;
}
.sharing li {
  float: left;
}
.sharing li:first-child {
  padding-left: 0;
}
.sharing .share-twitter {
  padding-top: 1px;
}
.sharing .share-facebook {
  padding-top: 1px;
}
.sharing .share-googleplus {
  margin-left: 30px;
}

/* POST NAVIGATION */








.node-page {
  .pink-button {
    .field-name-body,
    .field-name-field-link {
      float: left;
    }

    .field-name-body {
      width: 66%;
      padding-right: 2.5em;
    }

    .field-name-field-link {
      width: 34%;

      @include bp(phone) {
        padding: 0;
        padding-right: 10px;
      }

      a {
        background: #dc1b74 url('../images/arrow-next-white.png') no-repeat 95% center;
        color: #fff;
        padding: 15px 35px 15px 18px;
        border-radius: 5px;

        @include bp(phone) {
          display: block;
          padding-left: 8px;
        }
      }
    }
  }
}













.node-webform {
  @extend .container;
}
.node--webform {
  padding-top: 40px;
  padding-bottom: 40px
}
