$pad: 20px;

.grid {
	margin: 0 0 $pad 0;
	@include microclearfix();
}

[class*='col-'] {
	float: left;
	padding-right: $pad;
	.grid &:last-of-type {
		padding-right: 0;
	}
}

.col-1-2 {
	width: 50%;
}

.col-1-3 {
	width: 33.33%;
}
.col-2-3 {
	width: 66.66%;
}

.col-1-4 {
	width: 25%;
}
.col-3-4 {
	width: 75%;
}

.col-1-8 {
	width: 12.5%;
}
.col-7-8 {
	width: 87.5%;
}

.module {
	padding: $pad;
}

/* Opt-in outside padding */
.grid-pad {
	padding: $pad 0 $pad $pad;
	[class*='col-']:last-of-type {
		padding-right: $pad;
	}
}
